.stateroomTable tbody tr td {
  position: relative;
  z-index: 0;
}

.stateroomTable th,
.stateroomTable td {
  text-align: center !important;
}

.stateroomTable tr th:first-child {
  text-align: left !important;
}

.stateroomTableHeader > td,
.stateroomTableHeader > th {
  background-color: #e0e0e0 !important;
}

.expendedRow {
  background-color: #fff;
}

.expendedRow td {
  background-color: #fff !important;
}

.expendedRowText {
  text-align: left;
  font-size: .8rem;
  padding: 0;
  margin: 0;
}

@media screen and (min-width: 768px) {
  .expendedRowText {
    font-size: 1rem;
  }
}

.expendedRowText + expendedRowText {
  margin-top: 1.5em;
}