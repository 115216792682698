.slider {
  position: relative;
  height: inherit;
}

.arrows {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 12;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  pointer-events: none;
}

.arrow {
  width: 50px !important;
  height: 50px !important;
  color: #8b8b8b;
  pointer-events: auto;
}

.arrow:hover {
  color: #fff;
}

.arrow:active {
  color: rgba(255, 255, 255, 0.7);
}

.carousel {
  height: inherit;
  z-index: 10;
}