.item-wrapper {
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.31);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.31);
  margin: 8px;
  box-sizing: border-box;
  height: fit-content;
  min-width: 300px;
  padding-bottom: 24px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-bottom: 24px;
  margin: 20px 0;
  border-radius: 5px;
  overflow: hidden;
}
.btn-book-cruise {
  border-radius: 0;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -14px;
  right: 12px;
}
.image-cruise-item {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 320px;
}

.cruise-meta {
  padding: 8px;
  display: flex;
  justify-content: space-between;
}
.column-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
h3 {
  font-size: 16px;
}
p {
  margin: 0;
}
button {
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
}
.item-wrapper:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
}

.cruise-meta img.cruise-meta {
  object-fit: cover;
  height: 300px;
  width: 100%;
  padding: 0;
}

.cruise-meta h3 {
  font-size: 24px;
  margin-top: 10px;
}
.icon {
  margin-right: 8px;
  max-width: 100% !important;
}
