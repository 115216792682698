.ship-baner {
  background-color: '#e0e0e0';
  background-size: cover;
  background-position: center;
}
.ship-baner h2 {
  background-color: #f1f1f1;
  padding: 12px;
}
.staterooms-num {
  display: flex;
  flex-direction: column;
  background-color: #e0e0e0;
  max-height: 140px;
}
.staterooms-num div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.guests-num {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  background-color: #e0e0e0;
  max-height: 140px;
}
.guests-num div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cycle-btn {
  width: 50px;
  height: 50px;
  border: solid 4px black;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 46px;
}
button {
  padding: 5px 24px;
}
.details-form {
  background-color: slategrey;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #e0e0e0;
  align-items: center;
  font-size: 32px;
}
.details-form-container {
  display: flex;
  align-items: center;
  padding: 0 40px;
}
.details-form-container-staterooms {
  margin: 12px 24px;
}
.details-form {
  margin-top: 24px;
}
.details-form-total-inner > p {
  margin-right: 12px;
  margin-bottom: 0;
  font-size: 22px;
  padding-bottom: 0 !important;
}
.details-form-container-staterooms p,
.details-form-container-guests p {
  font-size: 22px;
  padding-bottom: 0 !important;
}
.details-form-container-guests {
  justify-content: center;
  align-items: center;
}
.card-border {
  border: solid 1px black;
}
.btn-continue-form {
  min-width: 300px;
  min-height: 84px;
}

.details-form-continue {
  position: sticky;
  background-color: #e0e0e0;
  width: 100%;
  padding: 20px;
  z-index: var(--zIndexFixedFooter);
  bottom: 0;
  /* height: 128px; */
}
.details-form-continue p {
  margin-bottom: 0 !important;
}
.cabins-type-card .ant-card-body {
  padding: 12px 6px;
}
