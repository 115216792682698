.sidebar {
  overflow-y: auto;
  /* position: sticky; */
  /* padding-bottom: 150px; fixed footer height */
  /* margin-bottom: 80px; */
}

@media screen and (min-width: 768px) {
  .sidebar {
    /* height: 100vh; */
    scrollbar-width: none;
  }

  .sidebar::-webkit-scrollbar {
    display: none;
  }
}
