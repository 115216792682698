.label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 6;
  cursor: pointer;
}

.label:hover ~ .bg {
  background-color: #eee;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 2;
}

.input {
  position: absolute;
  z-index: 4;
  height: 0;
  width: 0;
  opacity: 0;
  visibility: hidden;
}

.content {
  position: relative;
  z-index: 4;
}

.input:checked + .bg {
  background-color: #8affaa;
}