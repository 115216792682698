.ports-info-item {
  flex-wrap: wrap;
}

.ant-modal-root .ports-info-item {
  flex-wrap: wrap !important;
}

.ant-modal-root .ports-info-paragraph {
  white-space: initial;
  overflow: initial;
  text-overflow: initial;
}

@media screen and (min-width: 768px) {
  .ports-info-paragraph {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ports-info-item {
    flex-wrap: nowrap;
  }
}