.banner {
  position: relative;
  width: 100%;
  height: 300px;
  background-color: #F1F1F1;
  background-position: center center;
  background-size: cover;
  padding: 15px;
}

.banner > * {
  position: relative;
  width: inherit;
  height: inherit;
}


@media screen and (min-width: 786px) {
  .banner {
    height: 350px;
  }
}

@media screen and (min-width: 1024px) {
  .banner {
    height: 450px;
  }
}

@media screen and (min-width: 1360px) {
  .banner {
    height: 550px;
  }
}

.bannerImg {
  position: absolute !important;
  top: 0;
  left: 0;
  /*width: 100% !important;
  height: 100% !important;*/
  object-fit: cover;
  object-position: center;
}