.details-container {
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.31);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.31);
  margin-top: 64px;
  margin-bottom: 64px;
  padding: 32px;
  border-radius: 8px;
}
.bage {
  padding: 12px;
  width: fit-content;
  margin: 12px;
  background-color: crimson;
  color: white;
}
.gray-block {
  height: 320px;
  background-color: #dedede;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: 64px 0;
}
