.selectButton {
  position: relative;
}

.selectButton input {
/*  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;*/
}

.selectButtonLabel {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.selectButtonInput {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
}

.selectButtonIcon {
  font-size: 20px;
  margin-left: .3em;
  display: none !important;
}

.selectedText {
  display: none !important;
}

.selectButton input:checked + button {
  background-color: #12d438 !important;
}

.selectButton input:checked + button .selectButtonIcon {
  display: block !important;
}

.selectButton input:checked + button .defaultText {
  display: none;
}

.selectButton input:checked + button .selectedText {
  display: block !important;
}
