.row {
  display: flex;
  flex-wrap: wrap;
}

.margin-fix {
  margin: 0 -8px;
}
button .search-button {
  background-color: '#0B224E' !important;
}

.border-radius-0 {
  border-radius: 0 !important;
}

/* for mobil */
@media only screen and (max-width: 767px) {
  .search-container {
    display: flex;
    flex-direction: column !important;
  }

  .mobile-field {
    width: 100% !important;
    margin-bottom: 1rem;
  }

  .mobile-button {
    width: 100%;
    margin-top: 1rem;
  }
  .margin-fix {
    margin: 0 -4px;
  }

  .row > * {
    padding: 0 4px;
  }

  .col-xs-12,
  .col-sm-6,
  .col-md-4,
  .col-lg-2 {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .col-xs-12 {
    width: 100%;
  }

  .col-sm-6,
  .col-md-4,
  .col-lg-2 {
    width: 25%;
  }

  .row > * {
    margin-right: 15px;
  }

  .row > :last-child {
    margin-right: 0;
  }
}
