:root {
  --color-gray: #777777;
  --zIndexDetailForm: 50;
  --zIndexFixedFooter: 50;
  --zIndexItineraryModal: 100;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 320px;
}

.wrapper {
  position: relative;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.items-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  grid-gap: 20px;
}
.ant-breadcrumb-separator {
  margin-left: 4px !important;
  margin-right: 4px !important;
}
.ant-breadcrumb-link {
  text-transform: uppercase;
}
.breadcrumb {
  display: inline;
  text-transform: uppercase;
}
.breadcrumb-component {
  text-decoration: underline;
  cursor: pointer;
}
.breadcrumb-component-last {
  text-decoration: none !important;
  font-weight: bold;
}
.breadcrumb-separator {
  margin: 0 4px;
  color: #999;
}
.w-70 {
  width: 70%;
}
.w-25 {
  width: 25%;
}
.h-300 {
  height: 300px;
}
.h-800 {
  height: 800px;
}
.d-flex {
  display: flex;
  justify-content: space-between;
}
.p-12 {
  padding: 12px;
}
.text-center {
  text-align: center;
}
.mv-24 {
  margin-top: 24px;
  margin-bottom: 24px;
}
.card-border > .ant-card-bordered .ant-card-cover {
  margin: 0;
}
.p-0 {
  padding: 0 !important;
}
.ant-select-selection-item {
  text-align: left !important;
}

.input {
  border-radius: 0;
  padding: 0;
  border: none;
  border-bottom: 1px solid var(--color-gray) !important;
  font-size: 1rem;
}

.input label {
  display: flex;
  flex-direction: row-reverse;
}

.customFieldLabel {
  width: 100%;
  margin-bottom: 0 !important;
  font-size: 20px !important;
  color: #200d0d;
  font-weight: 700;
}

.customFieldIcon {
  width: 44px;
  height: 44px;
  margin-right: 10px;
  padding: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-gray);
}

.customFieldSelect {
  width: 100%;
  position: relative;
  border-radius: 0;
  margin-bottom: 24px;
  padding: 0;

  font-size: 1rem;
  display: flex;
  align-items: center;
}

.customFieldSelectItem {
  width: 100%;
  margin-bottom: 0;
}

.customFieldSelectItem .ant-form-item-control {
  width: 100%;
  height: 45px;
}

.customFieldSelectItem .ant-picker {
  width: 100%;
}

.customFieldSelectItem .ant-form-item-control-input {
  padding-left: 46px;
  min-height: 45px;
  border-bottom: 1px solid var(--color-gray);
}

.customFieldSelect * {
  font-size: 1rem;
}

.customFieldSelect .customFieldIcon {
  position: absolute;
  left: 0;
  bottom: 0;
}

.ant-modal-wrap {
  padding-top: 25px;
  padding-bottom: 25px;
}
.ant-card-body {
  padding: 0 12px !important;
}
