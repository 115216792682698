.container-xxl.new-design,
.container.new-design {
  max-width: 1440px;
}

.details-form-continue.new-design {
  background: #ceeff5;
  border-top: 2px solid #135665;
}
.details-form-continue.new-design .button {
  border-radius: 1rem;
}

.new-design + .container-xxl {
  max-width: 1440px;
}
.new-design .card,
.new-design .guests-num,
.new-design + .container-xxl div .ant-space-item {
  background: #fff;
  padding: 2rem;
  box-shadow: 0.5rem 0.5rem 0.5rem #ececec;
  border: 1px solid #ececec;
  border-radius: 0.8rem !important;
}
.new-design .card .mv-24.p-12,
.new-design .guests-num .mv-24.p-12,
.new-design + .container-xxl div .ant-space-item .mv-24.p-12 {
  background: #fff !important;
}
.new-design .card .mv-24.p-12 h2,
.new-design .guests-num .mv-24.p-12 h2,
.new-design + .container-xxl div .ant-space-item .mv-24.p-12 h2 {
  margin: 0 0 2rem;
}
.new-design .card .mv-24.p-12 .cabins-type-card,
.new-design .guests-num .mv-24.p-12 .cabins-type-card,
.new-design + .container-xxl div .ant-space-item .mv-24.p-12 .cabins-type-card {
  border-radius: 0.8rem !important;
  font-weight: 700;
}
.new-design .card .mv-24.p-12 .ant-alert.ant-alert-success,
.new-design .guests-num .mv-24.p-12 .ant-alert.ant-alert-success,
.new-design + .container-xxl div .ant-space-item .mv-24.p-12 .ant-alert.ant-alert-success {
  border-radius: 0 0 0.8rem 0.8rem !important;
  padding: 0.6rem 0 0.8rem;
  margin: -11px 0 0;
  background: #135665 !important;
  font-size: 1.6rem;
}
.new-design + .container-xxl div h1,
.new-design + .container-xxl div h2,
.new-design + .container-xxl div h3,
.new-design + .container-xxl div h4 {
  color: #135665;
}
.new-design + .container-xxl div .button {
  border-radius: 0.8rem !important;
  background: #135665;
  color: #fff;
  font-size: 22px;
  padding: 0.4rem 3rem;
}
.new-design + .container-xxl div .button:hover {
  background: #0b313a !important;
}
.new-design + .container-xxl div .ant-space-item {
  padding: 0;
}
.new-design + .container-xxl div .ant-space-item .guests-num {
  background: none;
  margin: 0;
  padding: 1rem 1rem 9rem;
}
.new-design + .container-xxl div .ant-space-item .ship-baner {
  border-radius: 0.8rem !important;
}
.new-design + .container-xxl div .ant-space-item .ship-baner h2 {
  border-radius: 0.8rem !important;
  position: relative;
  background: none;
  z-index: 2;
  color: #fff;
  opacity: 1;
}
/* .new-design + .container-xxl div .ant-space-item .ship-baner h2::after {
  width: 100%;
  height: 4rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  content: ' ';
  background: #135665;
  opacity: 0.8;
  display: block;
  border-radius: 0.8rem !important;
} */

.new-design .item-wrapper {
  box-shadow: 0.5rem 0.5rem 0.5rem #ececec;
  border: 1px solid #ececec;
}
.new-design h1 {
  font-style: normal;
  font-weight: 800 !important;
  line-height: 100%;
  margin: 0 0 2rem;
  display: flex;
  align-items: center;
  color: #200d0d;
  color: #135665;
  font-size: 44px;
  text-align: center;
  margin: 2rem 0;
  justify-content: center;
}
.new-design h2 {
  font-style: normal;
  font-weight: 800 !important;
  line-height: 100%;
  margin: 0 0 2rem;
  display: flex;
  align-items: center;
  color: #200d0d;
  color: #135665;
  font-size: 36px;
}
.new-design h3 {
  font-style: normal;
  font-weight: 800 !important;
  line-height: 100%;
  margin: 0 0 2rem;
  display: flex;
  align-items: center;
  color: #200d0d;
  color: #135665;
  font-size: 24px !important;
}
.new-design h4 {
  font-style: normal;
  font-weight: 800 !important;
  line-height: 100%;
  margin: 0 0 2rem;
  display: flex;
  align-items: center;
  color: #200d0d;
  color: #135665;
  font-size: 20px;
}
.new-design .button {
  border-radius: 0.8rem !important;
  background: #135665;
  color: #fff;
  font-size: 22px;
  padding: 0.55rem;
  font-weight: 600;
}
.new-design .button:hover {
  background: #0b313a !important;
}
.new-design .button.search-button {
  line-height: 0;
}
.new-design .button.btn-continue-form {
  margin: 0;
  max-width: 216px !important;
  padding: 0.4rem 2rem;
  min-width: inherit;
  min-height: inherit;
}
.new-design .button.btn-continue-form p {
  font-size: 28px !important;
}
.new-design #cabins .item-wrapper.col {
  width: 33%;
}
.new-design > .ant-row {
  max-width: 1440px;
  margin: auto;
}
.new-design
  .ant-col.ant-col-12.details-form-container-guests.css-dev-only-do-not-override-diro6f::before {
  color: red;
}
.new-design .svg-inline--fa {
  color: #135665;
  font-size: 34px;
}
.new-design .details-form-container-guests {
  border-right: 1px dashed #7f8284;
}
.new-design .details-form-total-inner p {
  font-size: 28px;
  font-weight: 800;
  color: #135665;
}

.cta-primary,
.ant-btn-primary {
  border-radius: 0.8rem !important;
  background: #135665;
  color: #fff;
  padding: 0.6rem 2rem;
  text-decoration: none;
  height: inherit;
}
.cta-primary:hover,
.ant-btn-primary:hover {
  background: #0b313a;
  color: #fff;
}
.cta-primary p,
.ant-btn-primary p {
  line-height: 10px;
  margin: 7px 0;
  font-weight: 700;
}
.cta-primary span,
.ant-btn-primary span {
  line-height: 10px;
  font-weight: 700;
}

.cta-secondary {
  border-radius: 0.8rem !important;
  background: #1d8197;
  color: #fff;
  padding: 0.6rem 2rem;
  text-decoration: none;
}
.cta-secondary:hover {
  background: #0b313a;
  color: #fff;
}
.cta-secondary__itin {
  display: block;
  margin: 2.2rem 0 0;
}

.ant-card.new-design {
  background: #fff;
  padding: 2rem;
  box-shadow: 0.5rem 0.5rem 0.5rem #ececec;
  border: 1px solid #ececec;
  border-radius: 0.8rem !important;
  padding: 0;
}
.ant-card.new-design h2 {
  margin: 2rem 0 0;
}
.ant-card.new-design .flex-shrink-0 {
  flex-shrink: inherit !important;
}
.ant-card.new-design .cta-secondary {
  padding: 0.6rem 1rem !important;
  font-weight: 700;
}
.ant-card.new-design .ant-list-items .ant-list-item {
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  gap: 0 !important;
  margin: 0 0 0.5rem;
}
.ant-card.new-design .ant-list-items .ant-list-item .ports-info-paragraph {
  white-space: inherit;
}
.ant-card.new-design .ant-list-items .ant-list-item strong {
  font-size: 15px;
  font-weight: 500;
}
.ant-card.new-design .ant-list-items .ant-list-item span {
  display: block;
}
.ant-card.new-design .ant-list-items .ant-list-item div {
  display: none;
}

img.ant-image-img.card-border {
  border-radius: 8px 8px 0 0 !important;
}

.StateroomTable_stateroomTableHeader__6husq .ant-table-cell {
  background: #135665 !important;
  color: #fff !important;
}
.StateroomTable_stateroomTableHeader__6husq .ant-typography {
  color: #fff;
}

.StateroomTableInput_input__lAHZ-:checked + .StateroomTableInput_bg__beXhu {
  background: #ceeff5 !important;
  color: #135665;
}

.ant-table-header.ant-table-sticky-holder table,
.ant-table-body table {
  width: 100% !important;
}

.ant-segmented .ant-typography {
  color: #135665;
}
.ant-segmented .ant-typography strong {
  color: #135665;
}

.ant-typography {
  color: #135665;
}
.ant-typography strong {
  color: #135665;
  font-weight: 700;
}

.ship-baner.new-design {
  background: #fff;
  padding: 2rem;
  box-shadow: 0.5rem 0.5rem 0.5rem #ececec;
  border: 1px solid #ececec;
  border-radius: 0.8rem !important;
  border-radius: 0.8rem !important;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center;
}
.ship-baner.new-design h2 {
  border-radius: 0.8rem !important;
  position: relative;
  background: none;
  z-index: 2;
  color: #fff;
  opacity: 1;
}
/* .ship-baner.new-design h2::after {
  width: 100%;
  height: 4rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  content: ' ';
  background: #135665;
  opacity: 0.8;
  display: block;
  border-radius: 0.8rem !important;
} */
.ship-baner.new-design ~ div {
  background: #fff;
  padding: 2rem;
  box-shadow: 0.5rem 0.5rem 0.5rem #ececec;
  border: 1px solid #ececec;
  border-radius: 0.8rem !important;
  border: 3px solid #135665 !important;
  margin: 1rem 0 0 !important;
}
.ship-baner.new-design ~ div div {
  margin: 0 !important;
}
.ship-baner.new-design ~ div div button {
  width: 120px;
  background: #1d8197 !important;
  border-radius: 0.8rem !important;
  color: #fff;
}
.ship-baner.new-design ~ .selected {
  background: #fff;
  padding: 2rem;
  box-shadow: 0.5rem 0.5rem 0.5rem #ececec;
  border: 1px solid #ececec;
  border-radius: 0.8rem !important;
  background: #ceeff5 !important;
  color: #000;
}
.ship-baner.new-design ~ .selected div {
  margin: 0 !important;
}
.ship-baner.new-design ~ .selected h3 {
  color: #135665 !important;
}
.ship-baner.new-design ~ .selected p {
  color: #000 !important;
  margin: 0 8rem 0 0;
}
.ship-baner.new-design ~ .selected button {
  width: 120px;
  background: #1d8197 !important;
  border-radius: 0.8rem !important;
  color: #fff;
}
.ship-baner.new-design ~ .selected ~ div {
  background: #fff;
  padding: 2rem;
  box-shadow: 0.5rem 0.5rem 0.5rem #ececec;
  border: 1px solid #ececec;
  border-radius: 0.8rem !important;
  border: 3px solid #135665 !important;
  color: #000;
}
.ship-baner.new-design ~ .selected ~ div button {
  width: 120px;
  background: #135665 !important;
  border-radius: 0.8rem !important;
  color: #fff;
}
.ship-baner.new-design ~ .selected ~ div div {
  margin: 0 !important;
}
.ship-baner.new-design ~ .selected ~ div h3 {
  color: #135665 !important;
}
.ship-baner.new-design ~ .selected ~ div p {
  color: #000 !important;
  margin: 0 8rem 0 0;
}

.new-design.result svg {
  width: 3rem;
  font-size: 22px;
}
.new-design.result .meta {
  color: #135665;
}
.new-design.result .btn-book-cruise b {
  color: #135665;
  margin: 0 1rem 0 0;
}
.new-design.result h3 {
  margin: 1rem;
}
.new-design.result .result-ctas button:nth-child(1) {
  width: 40%;
  background: #1d8197;
  border-radius: 0.8rem !important;
  color: #fff;
}
.new-design.result .result-ctas button:nth-child(2) {
  width: 40%;
  background: #135665;
  border-radius: 0.8rem !important;
  color: #fff;
}

.guests-num div div div svg {
  margin: 0 1rem;
}
.guests-num div div div span {
  color: #135665;
  font-weight: 700;
  margin: -5px 0 0;
}

.new-design.deck-plans div {
  background: #76d0e5 !important;
}
.new-design.deck-plans div h2 {
  margin: 0 2rem 2rem;
}
.new-design.deck-plans div .ant-select-selector,
.new-design.deck-plans div .ant-select-selection-item {
  background: #135665 !important;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
}
.new-design.deck-plans .ant-select-arrow {
  color: #fff !important;
  font-size: 18px;
}

.ant-pagination {
  margin: 2rem 0;
  border: 1px solid #ececec;
  border-radius: 0.8rem !important;
  box-shadow: 0.5rem 0.5rem 0.5rem #ececec;
  padding: 20px;
  text-align: center;
}
.ant-pagination-item {
  height: inherit !important;
  border: none !important;
}
.ant-pagination-item a {
  display: inherit !important;
  padding: 10px 20px !important;
  border-radius: 0.8rem !important;
  font-size: 16px;
  color: #135665 !important;
}
.ant-pagination-item-active a {
  background: #76d0e5;
  color: #135665 !important;
}
.ant-pagination .anticon {
  font-size: 16px;
  vertical-align: inherit;
}

.guest-header {
  font-size: 32px;
}

.guest-header svg path {
  fill: #135665;
}
.guest-header .gap-3 div {
  font-size: 20px;
  color: #135665;
  margin: 10px 0 0;
}

.traveler-form {
  /*.ant-input-affix-wrapper {
      width:inherit;
  }*/
}
.traveler-form .customFieldIcon {
  background: none;
}
.traveler-form svg path {
  fill: #135665;
  background: none !important;
}
.traveler-form .h1 {
  font-size: 24px;
  color: #135665;
}
.traveler-form .customFieldLabel,
.traveler-form .new-design-guest {
  font-size: 18px !important;
  color: #135665;
}
.traveler-form .ant-select.ant-select-in-form-item {
  width: inherit;
}
.traveler-form .new-design-title {
  width: 40%;
}
.traveler-form .new-design-gender {
  width: 45%;
}
.traveler-form .new-design-nationality {
  width: 50%;
}
.traveler-form .new-design-country {
  width: 45%;
}

header {
  background: #fff;
  padding: 2rem;
  box-shadow: 0.5rem 0.5rem 0.5rem #ececec;
  border: 1px solid #ececec;
  border-radius: 0.8rem !important;
  height: 110px;
  padding: 18px;
  margin: 0 0 20px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  background: #fff;
}
header img {
  height: 70px;
}

footer {
  text-align: center;
  padding: 20px;
  color: #135665;
}

.cabins > div {
  background: #fff;
  padding: 2rem;
  box-shadow: 0.5rem 0.5rem 0.5rem #ececec;
  border: 1px solid #ececec;
  border-radius: 0.8rem !important;
  border: 2px solid #135665;
  color: #135665 !important;
  margin: 0 0 1rem;
}
.cabins > div.selected {
  background: #76d0e5;
}
.cabins > div p {
  color: #135665 !important;
}
.cabins > div button {
  border-radius: 0.8rem !important;
  color: #fff;
}

.details-form-continue {
  padding: 10px;
}
.details-form-continue .svg-inline--fa {
  font-size: 22px;
}

.details-form-container-guests p {
  font-size: 16px;
  display: inline;
}

.price-pp::after {
  /* content: "pp"; */
}

.ant-card.shadow.new-design .ant-list-item {
  padding: 0 !important;
} /*# sourceMappingURL=new-design.css.map */
@media only screen and (max-width: 767px) {
  .banerFont {
    font-size: 16px !important;
  }
  .container-deck {
    display: flex;
    flex-direction: column;
  }
  .mobile-100 {
    width: 100% !important;
  }
}
