//Colours

$primary:#135665;
$secondary:#1D8197;
$tertiary:#76D0E5;
$light-blue:#A3E1ED;
$white:#fff;
$black:#000;
$grey:#7F8284;
$light-grey:#ececec;
$alert:red;

//Fonts 

$font-primary: Montserrat;
$font-secondary: Roboto;

//Sizing

$full-width:1440px;

.container-xxl.new-design, .container.new-design {
    max-width:$full-width;
}

.details-form-continue.new-design {
    background: lighten($light-blue, 10);
    border-top:2px solid $primary;
    .button {
        border-radius:1rem;
    }
}

//Mixins

//CTAs

@mixin header-style {
    //$font-primary;
    font-style: normal;
    font-weight: 800 !important;
    line-height: 100%;
    margin:0 0 2rem;
    display: flex;
    align-items: center;
    color: #200D0D;
}

@mixin card {
    background: $white;
    padding: 2rem;
    @include boxshadow();
    @include border();
    @include border-radius();
}

@mixin primary-cta {
    @include border-radius();
    background:$primary;
    color: $white;
    font-size:22px;
    &:hover {
        background:darken($primary, 10) !important;
    }
}

@mixin boxshadow {
    box-shadow: .5rem .5rem .5rem $light-grey;
}

@mixin border {
    border:1px solid $light-grey;
}

@mixin border-radius {
    border-radius: .8rem !important;
}

.new-design {

    //Container

    +.container-xxl {
        max-width:$full-width;
    }

    //Components

    .card, .guests-num, +.container-xxl div .ant-space-item{
        @include card();
        .mv-24.p-12 {
            background:$white !important;
            h2 {
                margin:0 0 2rem;
            }
            .cabins-type-card {
                @include border-radius();
                font-weight:700;
            }
            .ant-alert.ant-alert-success {
                border-radius:0 0 .8rem .8rem !important;
                padding: 0.6rem 0 0.8rem;
                margin: -11px 0 0;
                background:$primary !important;
                font-size:1.6rem;
            }
        }
    }

    +.container-xxl div {
        h1,h2,h3,h4 {
            color:$primary;
        }
        .button {
            @include primary-cta();
            padding:.4rem 3rem;
        }
        .ant-space-item {
            padding:0;
            .guests-num {
                background:none;
                margin:0;
                padding:1rem 1rem 9rem;
            }
            .ship-baner {
                @include border-radius();
                h2 {
                    @include border-radius();
                    position: relative;
                    background:none;
                    z-index: 2;
                    color:$white;
                    opacity: 1;
                    &::after {
                        width:100%;
                        height:4rem;
                        position: absolute;
                        top:0;
                        left:0;
                        z-index: -1;
                        content: ' ';
                        background:$primary;
                        opacity: .8;
                        display: block;
                        @include border-radius();
                    }
                }
            }
        }
    }

    .item-wrapper {
        @include boxshadow();
        @include border();
    }

    h1 {
        @include header-style;
        color:$primary;
        font-size: 44px;
        text-align: center;
        margin:2rem 0;
        justify-content: center;
    }

    h2 {
        @include header-style;
        color:$primary;
        font-size: 36px;
    }

    h3 {
        @include header-style;
        color: $primary;
        font-size: 24px !important;
    }

    h4 {
        @include header-style;
        color:$primary;
        font-size: 20px;
    }

    .button {
        @include primary-cta();
        padding: 0.55rem;
        font-weight: 600;
        &.search-button {
            line-height: 0;
        }
        &.btn-continue-form {
            padding:0.4rem 2rem;
            min-width:inherit;
            min-height:inherit;
            p {
                font-size:28px !important;
            }
        }
    }
    
    #cabins {
        .item-wrapper {
            &.col {
                width:33%;
            }
        }
    }

    >.ant-row {
        max-width:$full-width;
        margin: auto;
    }

    .ant-col.ant-col-12.details-form-container-guests.css-dev-only-do-not-override-diro6f {
        &::before {
            color:$alert;
        }
    }

    .svg-inline--fa {
        color:$primary;
        font-size:34px;
    }

    .details-form-container-guests {
        border-right: 1px dashed $grey;
    }

    .details-form-total-inner p {
        font-size:28px;
        font-weight:800;
        color:$primary;
    }

}

.cta-primary, .ant-btn-primary {
    @include border-radius();
    background:$primary;
    color:$white;
    padding:.6rem 2rem;
    text-decoration: none;
    height:inherit;
    &:hover {
        background:darken($primary, 10);
        color:$white;
    }
    p {
        line-height:10px;
        margin:7px 0;
        font-weight:700;
    }
    span {
        line-height:10px;
        font-weight:700;
    }
}

.cta-secondary {
    @include border-radius();
    background:$secondary;
    color:$white;
    padding:.6rem 2rem;
    text-decoration: none;
    &:hover {
        background:darken($primary, 10);
        color:$white;
    }
    &__itin {
        display:block;
        margin:2.2rem 0 0;
    }
}

.ant-card.new-design {
    @include card();
    padding:0;
    h2 {
        margin:2rem 0 0;
    }
    .flex-shrink-0 {
        flex-shrink: inherit !important;
    }
    .cta-secondary {
        padding:0.6rem 1rem !important;
        font-weight: 700;
    }
    .ant-list-items {
        .ant-list-item {
            flex-direction: row;
            flex-wrap: wrap;
            padding:0;
            gap:0 !important;
            margin:0 0 0.5rem;
            .ports-info-paragraph {
                white-space: inherit;
            }
            strong {
                font-size:15px;
                font-weight:500;
            }
            span {
                display:block;
            }
            div {
                display:none;
            }
        }
    }
}

img.ant-image-img.card-border {
    border-radius: 8px 8px 0 0 !important;
}

.StateroomTable_stateroomTableHeader__6husq {
    .ant-table-cell {
        background:$primary !important;
        color:$white !important;
    }
    .ant-typography {
        color:$white;
    }
}

.StateroomTableInput_input__lAHZ-:checked + .StateroomTableInput_bg__beXhu {
    background: lighten($light-blue, 10) !important;
    color:$primary;
}

.ant-table-header.ant-table-sticky-holder, .ant-table-body {
    table {
        width: 100% !important;
    }
}

.ant-segmented {
    .ant-typography {
        color:$primary;
        strong {
            color:$primary;
        }
    }
}

.ant-typography {
    color:$primary;
    strong {
        color:$primary;
        font-weight: 700;
    }
}

.ship-baner.new-design {
    @include card();
    @include border-radius();
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;
    h2 {
        @include border-radius();
        position: relative;
        background:none;
        z-index: 2;
        color:$white;
        opacity: 1;
        &::after {
            width:100%;
            height:4rem;
            position: absolute;
            top:0;
            left:0;
            z-index: -1;
            content: ' ';
            background:$primary;
            opacity: .8;
            display: block;
            @include border-radius();
        }
    }
    ~ div {
        @include card();
        border:3px solid $primary !important;
        margin:1rem 0 0 !important;
        div {
            margin:0 !important;
            button {
                width:120px;
                background:$secondary !important;
                @include border-radius();
                color:$white;
            }
        }
    }
    ~ .selected {
        @include card();
        background: lighten($light-blue, 10) !important;
        color:$black;
        div {
            margin:0 !important;
        }
        h3 {
            color:$primary !important;
        }
        p {
            color:$black !important;
            margin:0 8rem 0 0;
        }
        button {
            width:120px;
            background:$secondary !important;
            @include border-radius();
            color:$white;
        }
        & ~ div {
            @include card();
            border:3px solid $primary !important;
            color:$black;
            button {
                width:120px;
                background:$primary !important;
                @include border-radius();
                color:$white;
            }
            div {
                margin:0 !important;
            }
            h3 {
                color:$primary !important;
            }
            p {
                color:$black !important;
                margin:0 8rem 0 0;
            }
        }
    }
}

.new-design.result {
    svg {
        width: 3rem;
        font-size: 22px;
    }
    .meta {
        color:$primary;
    }
    .btn-book-cruise {
        b {
            color:$primary;
            margin:0 1rem 0 0;
        }
    }
    h3 {
        margin:1rem;
    }
    .result-ctas {
        button:nth-child(1) {
            width:40%;
            background:$secondary;
            @include border-radius();
            color:$white;
        }
        button:nth-child(2) {
            width:40%;
            background:$primary;
            @include border-radius();
            color:$white;
        }
    }
}

.guests-num {
    div {
        div {
            div {
                svg {
                    margin:0 1rem;
                }
                span {
                    color:$primary;
                    font-weight: 700;
                    margin:-5px 0 0;
                }
            }
        }
    }
}

.new-design.deck-plans {
    div {
        background:$tertiary !important;
        h2 {
            margin:0 2rem 2rem;
        }
        .ant-select-selector, .ant-select-selection-item {
            background:$primary !important;
            color:$white;
            font-weight: 700;
            font-size:18px;
        }
    }
    .ant-select-arrow {
        color:$white !important;
        font-size:18px;
    }
}

.ant-pagination {
    margin:2rem 0;
    @include border();
    @include border-radius();
    @include boxshadow();
    padding: 20px;
    text-align: center;
    &-item {
        height:inherit !important;
        border:none !important;
        a {
            display: inherit !important;
            padding:10px 20px !important;
            @include border-radius();
            font-size:16px;
            color: $primary !important;
        }
        &-active {
            a {
                background: $tertiary;
                color: $primary !important;
            }
        }
    }
    .anticon {
        font-size: 16px;
        vertical-align: inherit;
    }
}

.guest-header {
    font-size:32px;
}

.guest-header {
    svg {
        path {
            fill:$primary;
        }
    }
    .gap-3 {
        div {
            font-size: 20px;
            color:$primary;
            margin:10px 0 0;
        }
    }
}

.traveler-form {
    .customFieldIcon {
        background: none;
    }
    svg {
        path {
            fill:$primary;
            background:none !important;
        }
    }
    .h1 {
        font-size:24px;
        color:$primary;
    }
    .customFieldLabel, .new-design-guest {
        font-size: 18px !important;
        color:$primary;
    }
    .ant-select.ant-select-in-form-item {
        width:inherit;
    }
    .new-design-title {
        width:40%;
    }
    .new-design-gender {
        width:45%;
    }
    .new-design-nationality {
        width:50%;
    }
    .new-design-country {
        width:45%;
    }
    /*.ant-input-affix-wrapper {
        width:inherit;
    }*/
}

header {
    @include card();
    height: 110px;
    padding: 18px;
    margin:0 0 20px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;
    background:$white;
    img {
        height:70px;
    }
}

footer {
    text-align: center;
    padding:20px;
    color:$primary;
}

.cabins {
    > div {
        @include card();
        border:2px solid $primary;
        color:$primary !important;
        margin:0 0 1rem;
        &.selected {
            background: $tertiary;
        }
        p {
            color:$primary !important;
        }
        button {
            @include border-radius();
            color:$white;
        }
    }
}

.details-form-continue {
    padding:10px;
    .svg-inline--fa {
        font-size:22px;
    }
}

.details-form-container-guests p {
    font-size:16px;
    display:inline;
}

.price-pp {
    &::after {
        content: 'pp';
    }
}

.ant-card.shadow.new-design .ant-list-item {
    padding:0 !important;
}