.baner-container {
  width: 100%;
  min-height: 320px;
  background-color: #dedede;
  padding: 24px;
  display: flex;
  justify-content: flex-end;
}
.baner-header-bold {
  font-weight: bolder;
}

